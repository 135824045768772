import React, { useEffect, useState } from "react";
import close from "../../images/close.png";
const Form = ({ open, setOpen, produce }) => {
  //   console.log(produce.filter((item) => item.selected));
  const [s, setS] = useState([]);
  const c = [
    "Avocado",
    "Mango",
    "Pineapple",
    "Banana",
    "Passion Fruit",
    "Custard Apple",
    "Coffee",
    "Ginger",
    "Flowers",
    "Veggies",
  ];
  const [selected, setSelected] = useState([]);
  const onSelect = (v) => {
    if (selected.includes(v)) {
      setSelected(selected.filter((item) => item !== v));
    } else {
      setSelected([...selected, v]);
    }
  };
  useEffect(() => {
    setS(produce.filter((item) => item.selected));
    console.log(s);
  }, [produce]);
  return (
    <div className={`modal-container ${!open && "hide-modal"}`}>
      <div
        className={`modal-overlay ${!open && "hide-overlay"}`}
        onClick={() => setOpen(!open)}
      ></div>
      <div className={`modal`}>
        <div onClick={() => setOpen(!open)} style={{ zIndex: 999999999999999 }}>
          <img src={close} className="close" />
        </div>
        <h2 className="basket-title">Enquiry Form</h2>
        {/* <h3 className="basket-sub-title">Your basket</h3>
        <div className="selected">
          {s.length === 0 && <p>Your basket is empty</p>}
          {s.map((i) => {
            return (
              <div className="fruit-in-basket">
                {i.section} {i.type && <span>({i.type})</span>}
              </div>
            );
          })}
        </div> */}
        {/* <h3 className="basket-sub-title">Send us a messege</h3> */}
        <form
          className="basket-form"
          name="eform"
          action="/confirmation.html"
          method="POST"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="Enquiries" />

          <div>
            <label>Your name</label>
            <input type="text" name="name" />
          </div>
          <div>
            <label>Your company</label>
            <input type="text" name="company" />
          </div>
          <div>
            <label>Phone number</label>
            <input type="text" name="phone" />
          </div>
          <div>
            <label>E-mail</label>
            <input type="email" name="email" />
          </div>

          <label className="messege-lable">Messege</label>
          <textarea name="messege"></textarea>
          <br />
          <h3 className="ff">
            Select any of the following to enquire about them
          </h3>
          <div id="psps">
            {c.map((f) => {
              return (
                <span
                  onClick={() => onSelect(f)}
                  className={`${selected.includes(f) && "e-n-h"} tt`}
                >
                  {f}
                </span>
              );
            })}
          </div>
          <div style={{ display: "none" }}>
            <input type="text" name="data" value={selected} />
          </div>
          <button className="send-button">Send Messege</button>
        </form>
      </div>
    </div>
  );
};

export default Form;
