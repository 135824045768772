import React, { Fragment, useEffect, useState } from "react";
import fuerte from "../../images/fruits/furete.png";
import hass from "../../images/fruits/hass.png";
import jumbo from "../../images/fruits/jumbo.png";
import pinkerton from "../../images/fruits/pinkerton.png";
import kent from "../../images/fruits/kent.png";
import apple from "../../images/fruits/apple.png";
import ngowe from "../../images/fruits/ngowe.png";
import green from "../../images/fruits/green.png";
import francis from "../../images/fruits/francis.png";
import valenciaPride from "../../images/fruits/valencia-pride.png";
import md2 from "../../images/fruits/md2.png";
import sweetCayenne from "../../images/fruits/sweet-cayenne.jpg";
import williamCavendish from "../../images/fruits/william-cavendish.png";
import passionFruit from "../../images/fruits/passion-fruit.png";
import custardApple from "../../images/fruits/custard-apple.png";
import coffee from "../../images/fruits/coffee.png";
import ginger from "../../images/fruits/ginger.png";
import flowers from "../../images/fruits/flowers.png";
import veggies from "../../images/fruits/vegetables.png";
import basket from "../../images/fruit-basket.png";
import Form from "./Form";
import useIntersect from "../util/useIntersect";
const FruitSection = () => {
  const [open, setOpen] = useState(false);
  const [more, setMore] = useState(false);
  const [produce, setProduce] = useState([
    { selected: false, section: "Avocado", type: "Fuerte", source: ["K"] },
    { selected: false, section: "Avocado", type: "Hass", source: ["K"] },
    { selected: false, section: "Avocado", type: "Jumbo", source: ["K", "U"] },
    { selected: false, section: "Avocado", type: "Pinkerton", source: ["K"] },
    { selected: false, section: "Mango", type: "Apple", source: ["K"] },
    { selected: false, section: "Mango", type: "Kent", source: ["K"] },
    { selected: false, section: "Mango", type: "Ngowe", source: ["K"] },
    { selected: false, section: "Mango", type: "Green", source: ["K"] },
    { selected: false, section: "Mango", type: "Francis", source: ["K"] },
    {
      selected: false,
      section: "Passion Fruit",
      type: 1,
      source: ["K"],
    },
    {
      selected: false,
      section: "Custard Apple",
      type: 2,
      source: ["K"],
    },
    { selected: false, section: "Ginger", type: 3, source: ["K"] },
    { selected: false, section: "Coffee", type: 4, source: ["K"] },
    { selected: false, section: "Flowers", type: 5, source: ["K"] },
    { selected: false, section: "Veggies", type: 6, source: ["K"] },
    {
      selected: false,
      section: "Mango",
      type: "Valencia Pride",
      source: ["K"],
    },
    { selected: false, section: "Pineapple", type: "MD2", source: ["K"] },
    {
      selected: false,
      section: "Pineapple",
      type: "Sweet Cayenne",
      source: ["K"],
    },
    {
      selected: false,
      section: "Banana",
      type: "William Cavendish",
      source: ["K"],
    },
  ]);
  const toggleSelected = (type) => {
    setProduce(
      produce.map((item) =>
        item.type === type ? { ...item, selected: !item.selected } : item
      )
    );
  };

  const [ref1, entry1] = useIntersect({
    threshold: 0,
    rootMargin: "10% 0% 10% 0%",
  });
  useEffect(() => {
    if (entry1.isIntersecting) {
      if (entry1.isIntersecting) {
        document.querySelectorAll(".avocado-line").forEach((i) => {
          i.classList.add("card__line-a");
        });
        document.querySelectorAll(".avocado-img").forEach((i) => {
          i.classList.add("card__image-a");
        });
        document.querySelectorAll(".avocado-t").forEach((i) => {
          i.classList.add("card__content-a");
        });
        // document.getElementById("nav-fruits").classList.add("active");
        // setPosition1("services");
        // document.getElementById("service-title").classList.add("show-line");
      }
    }
    // eslint-disable-next-line
  }, [entry1.isIntersecting]);
  const [ref2, entry2] = useIntersect({
    threshold: 0,
    rootMargin: "10% 0% 10% 0%",
  });
  useEffect(() => {
    if (entry2.isIntersecting) {
      if (entry2.isIntersecting) {
        document.querySelectorAll(".mango-line").forEach((i) => {
          i.classList.add("card__line-a");
        });
        document.querySelectorAll(".mango-img").forEach((i) => {
          i.classList.add("card__image-a");
        });
        document.querySelectorAll(".mango-t").forEach((i) => {
          i.classList.add("card__content-a");
        });
        // document.getElementById("nav-fruits").classList.add("active");
        // setPosition1("services");
        // document.getElementById("service-title").classList.add("show-line");
      }
    }
    // eslint-disable-next-line
  }, [entry2.isIntersecting]);
  const [ref3, entry3] = useIntersect({
    threshold: 0,
    rootMargin: "10% 0% 10% 0%",
  });
  useEffect(() => {
    if (entry3.isIntersecting) {
      if (entry3.isIntersecting) {
        document.querySelectorAll(".pineapple-line").forEach((i) => {
          i.classList.add("card__line-a");
        });
        document.querySelectorAll(".pineapple-img").forEach((i) => {
          i.classList.add("card__image-a");
        });
        document.querySelectorAll(".pineapple-t").forEach((i) => {
          i.classList.add("card__content-a");
        });
        // document.getElementById("nav-fruits").classList.add("active");
        // setPosition1("services");
        // document.getElementById("service-title").classList.add("show-line");
      }
    }
    // eslint-disable-next-line
  }, [entry3.isIntersecting]);
  const [ref4, entry4] = useIntersect({
    threshold: 0,
    rootMargin: "10% 0% 10% 0%",
  });
  useEffect(() => {
    if (entry4.isIntersecting) {
      if (entry4.isIntersecting) {
        document.querySelectorAll(".banana-line").forEach((i) => {
          i.classList.add("card__line-a");
        });
        document.querySelectorAll(".banana-img").forEach((i) => {
          i.classList.add("card__image-a");
        });
        document.querySelectorAll(".banana-t").forEach((i) => {
          i.classList.add("card__content-a");
        });
        // document.getElementById("nav-fruits").classList.add("active");
        // setPosition1("services");
        // document.getElementById("service-title").classList.add("show-line");
      }
    }
    // eslint-disable-next-line
  }, [entry4.isIntersecting]);
  const [ref, entry] = useIntersect({
    threshold: 0,
    rootMargin: "-50% 0% -50% 0%",
  });
  useEffect(() => {
    if (entry.isIntersecting) {
      if (entry.isIntersecting) {
        document.querySelectorAll(".nav-link").forEach((i) => {
          i.classList.remove("active");
        });
        document.getElementById("nav-fruits").classList.add("active");
        // setPosition1("services");
        // document.getElementById("service-title").classList.add("show-line");
      }
    }
    // eslint-disable-next-line
  }, [entry.isIntersecting]);
  return (
    <div className="fruit-section" id="fruits" ref={ref}>
      <Form open={open} setOpen={setOpen} produce={produce} />

      <h2 className="section-title">Tropical Treasures</h2>
      <section className="fruit-page">
        <div className="avocado-s">AVOCADO</div>
        <br />
        <section className="single-fruit-section" ref={ref1}>
          {produce.map((p) => {
            if (p.section === "Avocado") {
              return (
                <div>
                  <div className="card" onClick={() => toggleSelected(p.type)}>
                    {/* <div className="marked">
                      {p.selected && <div className="iammarked" />}
                    </div> */}
                    <div className="card__image-container">
                      <img
                        className="card__image avocado-img ssimg"
                        src={
                          p.type === "Fuerte"
                            ? fuerte
                            : p.type === "Hass"
                            ? hass
                            : p.type === "Jumbo"
                            ? jumbo
                            : pinkerton
                        }
                        alt=""
                      />
                    </div>
                    <svg className="card__svg" viewBox="0 0 800 500">
                      <path
                        d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400 L 800 500 L 0 500"
                        stroke="transparent"
                        fill="#ddd"
                      />
                      <path
                        className="card__line avocado-line"
                        d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 0 550 150 650 300 Q 750 450 800 400"
                        stroke="green"
                        strokeWidth={3}
                        fill="transparent"
                      />
                    </svg>
                    <div className="card__content avocado-t">
                      <h1 className="card__title avocado">{p.type}</h1>
                    </div>
                  </div>
                </div>
              );
            }
            return;
          })}
        </section>

        <section className="single-fruit-section" ref={ref2}>
          <div className="mango-s">MANGO</div>
          {produce.map((p) => {
            if (p.section === "Mango") {
              return (
                <div>
                  <div
                    className={`
                    ${p.type === "Francis" && "card50"}
                    ${p.type === "Valencia Pride" && "card50"}
                    card`}
                    onClick={() => toggleSelected(p.type)}
                  >
                    {/* <div className="marked">
                      {p.selected && <div className="iammarked" />}
                    </div> */}
                    <div className="card__image-container">
                      <img
                        className={`card__image mango-img  ${
                          p.type === "Francis" && "widthit"
                        }
                        ${p.type === "Valencia Pride" && "widthit"}`}
                        src={
                          p.type === "Kent"
                            ? kent
                            : p.type === "Apple"
                            ? apple
                            : p.type === "Ngowe"
                            ? ngowe
                            : p.type === "Francis"
                            ? francis
                            : p.type === "Green"
                            ? green
                            : valenciaPride
                        }
                        alt=""
                      />
                    </div>
                    <svg
                      className={`
                      ${p.type === "Francis" && "card50svg"}
                      ${p.type === "Valencia Pride" && "card50svg"}
                       card__svg`}
                      viewBox="0 0 800 500"
                    >
                      <path
                        d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400 L 800 500 L 0 500"
                        stroke="transparent"
                        fill="#ddd"
                      />
                      <path
                        className="card__line mango-line"
                        d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 0 550 150 650 300 Q 750 450 800 400"
                        stroke="green"
                        strokeWidth={
                          p.type === "Francis"
                            ? 1.5
                            : p.type === "Valencia Pride"
                            ? 1.5
                            : 3
                        }
                        fill="transparent"
                      />
                    </svg>
                    <div className="card__content mango-t">
                      <h1 className="card__title mango">{p.type}</h1>
                    </div>
                  </div>
                </div>
              );
            }
            return;
          })}
        </section>

        <section className="single-fruit-section" ref={ref3}>
          <div className="pineapples-s">PINEAPPLE</div>

          {produce.map((p) => {
            if (p.section === "Pineapple") {
              return (
                <div>
                  <div
                    className="card50 card "
                    onClick={() => toggleSelected(p.type)}
                  >
                    {/* <div className="marked">
                      {p.selected && <div className="iammarked" />}
                    </div> */}
                    <div className="card__image-container">
                      <img
                        className="card__image pineapple-img widthit"
                        src={p.type === "MD2" ? md2 : sweetCayenne}
                        alt=""
                      />
                    </div>
                    <svg className="card50svg card__svg " viewBox="0 0 800 500">
                      <path
                        d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400 L 800 500 L 0 500"
                        stroke="transparent"
                        fill="#ddd"
                      />
                      <path
                        className="card__line pineapple-line"
                        d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 0 550 150 650 300 Q 750 450 800 400"
                        stroke="green"
                        strokeWidth={1.5}
                        fill="transparent"
                      />
                    </svg>
                    <div className="card__content pineapple-t">
                      <h1 className="card__title pineapple">{p.type}</h1>
                    </div>
                  </div>
                </div>
              );
            }
            return;
          })}
        </section>

        <section className="single-fruit-section" ref={ref4}>
          <div className="banana-s">BANANA</div>

          {produce.map((p) => {
            if (p.section === "Banana") {
              return (
                <div>
                  <div
                    className="card card100"
                    onClick={() => toggleSelected(p.type)}
                  >
                    {/* <div className="marked">
                      {p.selected && <div className="iammarked" />}
                    </div> */}
                    <div className="card__image-container">
                      <img
                        className="card__image banana-img widthit"
                        src={williamCavendish}
                        alt=""
                      />
                    </div>
                    <svg className="card__svg card100svg" viewBox="0 0 800 500">
                      <path
                        d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400 L 800 500 L 0 500"
                        stroke="transparent"
                        fill="#ddd"
                      />
                      <path
                        className="card__line banana-line"
                        d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 0 550 150 650 300 Q 750 450 800 400"
                        stroke="green"
                        strokeWidth={1}
                        fill="transparent"
                      />
                    </svg>
                    <div className="card__content banana-t">
                      <h1 className="card__title banana">{p.type}</h1>
                    </div>
                  </div>
                </div>
              );
            }
            return;
          })}
        </section>
        {more && (
          <Fragment>
            <div className="pf-ca-s">
              <span>PASSION FRUIT</span>
            </div>

            <section className="single-fruit-section">
              {produce.map((p) => {
                if (p.section === "Passion Fruit") {
                  return (
                    <div>
                      <div
                        className="card card100"
                        onClick={() => toggleSelected(p.type)}
                      >
                        {/* <div className="marked">
                          {p.selected && <div className="iammarked" />}
                        </div> */}
                        <div className="card__image-container">
                          <img
                            className="card__image card__image-a widthit nodelay"
                            src={passionFruit}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
                return;
              })}
            </section>
            <div className="pf-ca-s">
              <span>CUSTARD APPLE</span>
            </div>

            <section className="single-fruit-section">
              {produce.map((p) => {
                if (p.section === "Custard Apple") {
                  return (
                    <div>
                      <div
                        className="card card100"
                        onClick={() => toggleSelected(p.type)}
                      >
                        {/* <div className="marked">
                          {p.selected && <div className="iammarked" />}
                        </div> */}
                        <div className="card__image-container">
                          <img
                            className="card__image card__image-a widthit nodelay"
                            src={custardApple}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
                return;
              })}
            </section>
            <section className="single-fruit-section">
              <div className="c-g-s">
                <span>COFFEE</span>
              </div>
              {produce.map((p) => {
                if (p.section === "Coffee") {
                  return (
                    <div>
                      <div
                        className="card card100"
                        onClick={() => toggleSelected(p.type)}
                      >
                        {/* <div className="marked">
                          {p.selected && <div className="iammarked" />}
                        </div> */}
                        <div className="card__image-container">
                          <img
                            className="card__image card__image-a widthit nodelay"
                            src={coffee}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
                return;
              })}
            </section>
            <section className="single-fruit-section">
              <div className="c-g-s">
                <span>GINGER</span>
              </div>

              {produce.map((p) => {
                if (p.section === "Ginger") {
                  return (
                    <div>
                      <div
                        className="card card100"
                        onClick={() => toggleSelected(p.type)}
                      >
                        {/* <div className="marked">
                          {p.selected && <div className="iammarked" />}
                        </div> */}
                        <div className="card__image-container">
                          <img
                            className="card__image card__image-a widthit nodelay"
                            src={ginger}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
                return;
              })}
            </section>
            <section className="single-fruit-section">
              <div className="f-v-s">
                <span>FLOWERS</span>
              </div>
              {produce.map((p) => {
                if (p.section === "Flowers") {
                  return (
                    <div>
                      <div
                        className="card card100"
                        onClick={() => toggleSelected(p.type)}
                      >
                        {/* <div className="marked">
                          {p.selected && <div className="iammarked" />}
                        </div> */}
                        <div className="card__image-container">
                          <img
                            className="card__image card__image-a widthit nodelay"
                            src={flowers}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
                return;
              })}
            </section>
            <section className="single-fruit-section">
              <div className="f-v-s">
                <span>VEGGIES</span>
              </div>

              {produce.map((p) => {
                if (p.section === "Veggies") {
                  return (
                    <div>
                      <div
                        className="card card100"
                        onClick={() => toggleSelected(p.type)}
                      >
                        {/* <div className="marked">
                          {p.selected && <div className="iammarked" />}
                        </div> */}
                        <div className="card__image-container">
                          <img
                            className="card__image card__image-a widthit nodelay"
                            src={veggies}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
                return;
              })}
            </section>
          </Fragment>
        )}
      </section>
      {!more && (
        <button className="show-more" onClick={() => setMore(true)}>
          Show more
        </button>
      )}
      {/* <button className="basket" onClick={() => setOpen(!open)}>
        <img src={basket} />
        <span>Fruit Basket</span>
        {produce.filter((item) => item.selected).length !== 0 && (
          <div className="badge">
            {produce.filter((item) => item.selected).length}
          </div>
        )}
      </button> */}
      <h2 className="f-headline">Are you ready to entice your senses?</h2>
      <br />
      <button className="f-button" onClick={() => setOpen(!open)}>
        Fill enquiry form
      </button>
    </div>
  );
};

export default FruitSection;
