import React, { Fragment } from "react";

const FruitNav = () => {
  return (
    <Fragment />
    // <nav className="fruit-nav">
    //   <ul>
    //     <li>Avocado</li>
    //     <li>Mango</li>
    //     <li>Pineapple</li>
    //     <li>Banana</li>
    //     <li>Passion Fruit</li>
    //     <li>Custard Apple</li>
    //     <li>Flowers</li>
    //     <li>Ginger</li>
    //     <li>Coffee</li>
    //     <li>Veggies</li>
    //   </ul>
    // </nav>
  );
};

export default FruitNav;
