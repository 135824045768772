import "./App.css";
import About from "./components/about/About";
import Contact from "./components/Contact/Contact";
import FruitSection from "./components/fruits/FruitSection";
import HeroHeader from "./components/home/HeroHeader";
import FruitNav from "./components/layout/FruitNav";
import Nav from "./components/layout/Nav";
// import Navigation from "./components/layout/FruitNav";
import NavBar from "./components/layout/NavBar";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Nav />
      <HeroHeader />
      <FruitSection />
      <FruitNav />
      <About />
      <Contact />
    </div>
  );
}

export default App;
