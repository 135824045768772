import React, { useState, useEffect, useRef } from "react";
import gif from "../../images/gif.gif";
const Pre = () => {
  const [gifLoaded, setGifLoaded] = useState(false);
  const [gifHasPlayed, setGifHasPlayed] = useState(false);

  useEffect(() => {
    if (gifLoaded) {
      const timeoutId = setTimeout(() => {
        setGifHasPlayed(true);
      }, 3500);
      return () => clearTimeout(timeoutId);
    }
  }, [gifLoaded]);
  return (
    <div className={`pre ${gifHasPlayed && "move-left"}`}>
      <img
        className={`gif ${gifHasPlayed && "go-away"}`}
        src={gif}
        onLoad={() => setGifLoaded(true)}
        onError={() => setGifLoaded(false)}
      />
    </div>
  );
};

export default Pre;
