import React, { useEffect, useRef, useState } from "react";
import "../../css/mob-nav.css";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ShoppingBasketRoundedIcon from "@mui/icons-material/ShoppingBasketRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
const Nav = () => {
  const buttonRef = useRef(null);
  const wrapperRef = useRef(null);
  const overlayRef = useRef(null);
  const [openz, setOpenz] = useState(false);
  useEffect(() => {
    const button = buttonRef.current;
    const wrapper = wrapperRef.current;
    const overlay = overlayRef.current;

    let open = false;

    button.addEventListener("click", handler, false);
    button.addEventListener("focus", handler, false);
    wrapper.addEventListener("click", cnhandle, false);

    function cnhandle(e) {
      e.stopPropagation();
    }

    function handler(e) {
      if (!e) var e = window.event;
      e.stopPropagation();

      if (!open) {
        openNav();
      } else {
        closeNav();
      }
    }

    function openNav() {
      open = true;
      setOpenz(true);
      overlay.classList.add("on-overlay");
      wrapper.classList.add("opened-nav");
    }

    function closeNav() {
      open = false;
      setOpenz(false);
      //   button.innerHTML = "+";
      overlay.classList.remove("on-overlay");
      wrapper.classList.remove("opened-nav");
    }

    document.addEventListener("click", closeNav);
  }, []);

  return (
    <div className="classx wrap-mob-ss">
      <button ref={buttonRef} className="cn-button classx" id="cn-button">
        {openz ? <CloseRoundedIcon /> : <MenuRoundedIcon />}
      </button>
      <div ref={wrapperRef} className="cn-wrapper classx" id="cn-wrapper">
        <ul className="classx">
          <li className="classx">
            <a href="#home" className="classx">
              <HomeRoundedIcon />
            </a>
          </li>
          <li className="classx">
            <a href="#fruits" className="classx">
              <ShoppingBasketRoundedIcon />
            </a>
          </li>
          <li className="classx">
            <a href="#about" className="classx">
              <QuestionMarkRoundedIcon />
            </a>
          </li>
          <li className="classx">
            <a href="#contact" className="classx">
              <CallRoundedIcon />
            </a>
          </li>
          <li className="classx">
            <a href="#contact" className="classx"></a>
          </li>
        </ul>
      </div>
      <div ref={overlayRef} id="cn-overlay" className="cn-overlay classx"></div>
    </div>
  );
};

export default Nav;
