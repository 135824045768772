import React, { Fragment, useEffect } from "react";
import hero from "../../images/hero.png";
import heroM from "../../images/hero-mob.webp";
import { Link } from "react-scroll";
import useIntersect from "../util/useIntersect";
import Pre from "../layout/Pre";

const HeroHeader = () => {
  const [ref, entry] = useIntersect({
    threshold: 0,
    rootMargin: "-50% 0% -50% 0%",
  });
  useEffect(() => {
    if (entry.isIntersecting) {
      if (entry.isIntersecting) {
        document.querySelectorAll(".nav-link").forEach((i) => {
          i.classList.remove("active");
        });
        document.getElementById("nav-home").classList.add("active");
        // setPosition1("services");
        // document.getElementById("service-title").classList.add("show-line");
      }
    }
    // eslint-disable-next-line
  }, [entry.isIntersecting]);
  return (
    <Fragment>
      <Pre />
      <div className="hero-container" id="home" ref={ref}>
        <img src={hero} alt="namaa" className="hero-img" />
        <img src={heroM} alt="namaa" className="hero-mob-img" />

        <div className="hero-text-container">
          <h1 data-text="Experience our fresh fruit aroma">
            <span className="header-span" data-text="anywhere in the world...">
              Experience our fresh fruit aroma
              <br />
              anywhere in the world...
            </span>
          </h1>
          <br />
          <div className="text">
            From farm to shipment, the freshness of our produce remains intact.
          </div>
          <Link
            to="fruits"
            smooth="easeInQuad"
            offset={-100}
            duration={500}
            id="nav-home"
            isDynamic={true}
          >
            <button className="action-button">View Fruit Selection</button>
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

export default HeroHeader;
