import React, { useEffect } from "react";
import tree from "../../images/tree.png";
import facebook from "../../images/f.svg";
import instagram from "../../images/i.svg";
import whatsapp from "../../images/w.svg";
import pin from "../../images/l.svg";
import phone from "../../images/p.svg";
import useIntersect from "../util/useIntersect";
import email from "../../images/e.svg";
const Contact = () => {
  const [ref, entry] = useIntersect({
    threshold: 0,
    rootMargin: "-50% 0% -50% 0%",
  });
  useEffect(() => {
    if (entry.isIntersecting) {
      if (entry.isIntersecting) {
        document.querySelectorAll(".nav-link").forEach((i) => {
          i.classList.remove("active");
        });
        document.getElementById("nav-contact").classList.add("active");
        // setPosition1("services");
        // document.getElementById("service-title").classList.add("show-line");
      }
    }
    // eslint-disable-next-line
  }, [entry.isIntersecting]);
  return (
    <div className="contact" id="contact" ref={ref}>
      <h2>Get in touch</h2>
      <div className="contact-details">
        <div className="details">
          {/* <div className="links">
            <a>
              <img src={pin} />
            </a>
            <a>
              <img src={facebook} />
            </a>
            <a className="show-mob">
              <img src={instagram} />
            </a>
            <a className="show-mob">
              <img src={whatsapp} />
            </a>
          </div> */}
          <div className="tits">
            <a
              href="https://www.facebook.com/profile.php?id=100064035405729&notif_id=1599826602090644&notif_t=page_instagram_follower&ref=notif"
              target="_blank"
            >
              <img src={facebook} />
            </a>
            <a href="https://goo.gl/maps/hyNXaedhztK35PFa8" target="_blank">
              <img src={pin} />
            </a>
            <a
              href="https://instagram.com/namaa_fruits?igshid=Zjc2ZTc4Nzk="
              target="_blank"
            >
              <img src={instagram} />
            </a>
            <a href="https://wa.me/+254703659098" target="_blank">
              <img src={whatsapp} />
            </a>
          </div>

          <div className="numbers">
            <a href="tel:+254703659098">
              <img src={phone} />
              <span>+254 703 659 098</span>
            </a>
            <a href="mailto:info@namaafruits.com">
              <img src={email} />
              info@namaafruits.com
            </a>
          </div>
          {/* <div className="links hide-mob">
            <a>
              <img src={instagram} />
            </a>
            <a>
              <img src={whatsapp} />
            </a>
          </div> */}
        </div>
        <div className="tree">
          <img src={tree} />
        </div>
      </div>
      <h3 className="ss">For more information about our produce</h3>
      <br />
      <a href="/brochure.pdf" target="_blank" className="db">
        Download Brochure
      </a>
      <div className="cp">
        Copyright © 2023{" "}
        <a href="https://iano.marketing" target="_blank">
          iano
        </a>
        . All rights reserved.
      </div>
    </div>
  );
};

export default Contact;
