import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import { Link } from "react-scroll";
const NavBar = () => {
  const [shrink, setShrink] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 20) {
      setShrink(true);
    } else {
      setShrink(false);
    }
  };

  return (
    <nav id="nav-bar" className={`nav-bar ${shrink && "shrink"}`}>
      <img src={logo} alt="namaa" className="nav-logo" />
      <ul className="nav-list">
        <li className="nav-item ">
          <Link
            to="home"
            smooth="easeInQuad"
            offset={0}
            duration={500}
            className="nav-link active"
            id="nav-home"
            isDynamic={true}
          >
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="fruits"
            smooth="easeInQuad"
            offset={-100}
            duration={500}
            className="nav-link"
            id="nav-fruits"
            isDynamic={true}
          >
            Fruits
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="about"
            smooth="easeInQuad"
            offset={-100}
            duration={500}
            className="nav-link"
            id="nav-about"
            isDynamic={true}
          >
            Why Namaa?
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="contact"
            smooth="easeInQuad"
            offset={0}
            duration={500}
            className="nav-link"
            id="nav-contact"
            isDynamic={true}
          >
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
